@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --hue-color: 220;
  --color-primary: hsl(var(--hue-color), 69%, 61%);
  --first-color-second: hsl(var(--hue-color), 69%, 75%);
  /* --first-color-alt: hsl(var(--hue-color), 57%, 53%); */
  --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
  --title-color: hsl(var(--hue-color), 8%, 15%);
  --text-color: hsl(var(--hue-color), 8%, 5%);
  --text-color-light: hsl(var(--hue-color), 8%, 65%);
  /* --input-color: hsl(var(--hue-color), 70%, 96%); */
  --container-color: hsl(var(--hue-color), 60%, 90%);
  --body-color: #fff;
  /* --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 80%); */
}

@layer utilities {
  .dark {
    --first-color-second: hsl(var(--hue-color), 50%, 30%);
    --title-color: hsl(var(--hue-color), 8%, 95%);
    --text-color: hsl(var(--hue-color), 8%, 75%);
    --input-color: hsl(var(--hue-color), 29%, 16%);
    --body-color: hsl(var(--hue-color), 28%, 12%);
    --container-color: hsl(var(--hue-color), 29%, 16%);
    /* --scroll-bar-color: hsl(var(--hue-color), 12%, 48%);
    --scroll-thumb-color: hsl(var(--hue-color), 12%, 36%); */
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-gray {
  background: gray !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.bg-blue {
  background: #007aff !important;
}

.opacity-full {
  opacity: 1 !important;
}